import headerLogo from "../images/passio-logo-color.png";
import browseAnnotion from "../images/annotationScreen.png";
import appleLogo from "../images/apple-64.png";
import droidLogo from "../images/android.svg";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useWebSocket from "react-use-websocket";
import { useNavigate } from "react-router-dom";

const MindEye = () => {
  const navigate = useNavigate();

  const refLikeWhatYouDiv = useRef(null);
  const refMainInterfaceDiv = useRef(null);
  const refInterfaceQueuedDiv = useRef(null);
  const refStreamContainerElement = useRef(null);
  const refSnapshotCaptureCanvas = useRef(null);
  const refPredictedLabelBox = useRef(null);
  const refPredictionConfidenceBox = useRef(null);
  const refTextEmbeddingsTextBox = useRef(null);
  const refUpdateEmbeddingsBtn = useRef(null);

  const socketUrl = process.env.REACT_APP_SOCKET_URL;

  const [currentTextEmbeddingsText, setCurrentTextEmbeddingsText] =
    useState("");
  const [sendSnapshotsLoopEnabled, setSendSnapshotsLoopEnabled] =
    useState(false);
  const [sendQueuePosRequestsEnabled, setSendQueuePosRequestsEnabled] =
    useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentQueueNum, setCurrentQueueNum] = useState("0");
  const [currentSensitivitiesText, setCurrentSensitivitiesText] = useState(
    <div></div>
  );
  const [btnClicked, setBtnClicked] = useState(false);
  const [btnText, setBtnText] = useState("Update Model");
  const [facingMode, setFacingMode] = useState("user");
  const [swapCamEnabled, setSwapCamEnabled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [updateTimer, setUpdateTimer] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [dlEnabled, setDlEnabled] = useState(true);
  const [dlText, setDlText] = useState("Download");
  const [errTimerState, setErrTimerState] = useState("update");
  const [isDownloading, setIsDownloading] = useState(false);
  const [dlSuccess, setDlSuccess] = useState(false);

  const APIWaitTimeoutSec = 120;

  // Blue and Focus Functions
  // These will disable or ensable the camera as the user is on or off this page
  function onFocus() {
    if (!sendSnapshotsLoopEnabled && !sendQueuePosRequestsEnabled) {
      startStreaming();
    }
  }
  function onBlur() {
    if (sendSnapshotsLoopEnabled) {
      stopStreaming();
    }
  }

  function toggleEditMode(editOn) {
    let sensitiveDiv = document.getElementById("sensitiveDiv");
    let txtArea = document.getElementById("textAreaDiv");
    let dlButton = document.getElementById("btn-dl-mlmodel");
    let ghButton = document.getElementById("btn-ios-repo");
    let dlButtonA = document.getElementById("btn-dl-tfmodel");
    let ghButtonA = document.getElementById("btn-android-repo");
    if (editOn) {
      setBtnText("Update Model");
      sensitiveDiv.style.display = "none";
      dlButton.style.display = "none";
      ghButton.style.display = "none";
      dlButtonA.style.display = "none";
      ghButtonA.style.display = "none";
      txtArea.style.display = "block";
    } else {
      setBtnText("Edit Model");
      sensitiveDiv.style.display = "block";
      dlButton.style.display = "block";
      ghButton.style.display = "block";
      dlButtonA.style.display = "block";
      ghButtonA.style.display = "block";
      txtArea.style.display = "none";
    }
  }

  function emailIsSubmitted() {
    return localStorage.getItem("mindseye-email-sent") === "true";
  }

  // No usage if you havent given us an email!
  useEffect(() => {
    if (emailIsSubmitted() === false) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateTimer > 0) {
      setTimeout(() => setUpdateTimer(updateTimer - 1), 1000);
    } else {
      if (errTimerState === "update") {
        if (!firstLoad && !sendSnapshotsLoopEnabled) {
          toast.error("Updating the model timed out. Please try again.", {
            theme: "dark",
          });
          recvTextSensitivitiesUpdated(null);
        }
        setFirstLoad(false);
      } else if (errTimerState === "download" && dlSuccess === false) {
        recvDownloadModelResponse(null, "mlmodel");
        toast.error("Downloading the model timed out. Please try again.", {
          theme: "dark",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTimer]);

  useEffect(() => {
    let op = document.getElementById("outerDiv");
    window.onclick = (event) => {
      let btn = document.getElementById("btn-update-text");
      // if we click in the div, we swap to edit mode
      if (op.contains(event.target) && !isDownloading) {
        toggleEditMode(true);
      } else {
        // Otherwise, if we click anywhere thats NOT the action button, we swap out of edit mode
        if (!btn.contains(event.target)) {
          toggleEditMode(false);
        }
      }
    };
  }, [btnClicked, isDownloading]);

  // Apply event listeners to the window to disable or enable the camera
  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendSnapshotsLoopEnabled, sendQueuePosRequestsEnabled]);

  // SOCKETING

  // Creates the websocket connection
  const { sendMessage } = useWebSocket(socketUrl, {
    // onOpen: () => {},
    onClose: () => {
      disablePage();
    },
    shouldReconnect: () => true,
    onMessage: (event) => {
      // run function that receives our messages and handles responses
      receiveMessage(event);
    },
  });

  // Message Receiver
  function receiveMessage(event) {
    const data = JSON.parse(event.data);

    // Handles the cases for message types and sends appropriate responses
    switch (data.type) {
      case "update_text_response":
        recvTextSensitivitiesUpdated(data);
        break;
      case "process_image_response":
        recvProcessImageResponse(data);
        break;
      case "request_current_text_response":
        recvCurrentTextResponse(data);
        break;
      case "request_embedding_vectors_response":
        recvCurrentEmbeddingVectorsResponse(data);
        break;
      case "connection_ready":
        enablePage();
        recvTextSensitivitiesUpdated(data);
        break;
      case "connection_queue_status_response":
        recvQueueStatusResponse(data);
        break;
      case "download_mlmodel_response":
        recvDownloadModelResponse(data, "mlmodel");
        break;
      case "download_tfmodel_response":
        recvDownloadModelResponse(data, "tflite");
        break;
      case "ios_repo_view_response":
        break;
      case "android_repo_view_response":
        break;
      default:
        toast.error("Unknown message type: " + data.type, {
          theme: "dark",
        });
    }
  }
  function sendRequest(data) {
    sendMessage(JSON.stringify(data));
  }

  const Loader = () => {
    return (
      <div role="status" className="flex items-center">
        <svg
          aria-hidden="true"
          className="w-8 !h-[25px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="lg:text-xl md:text-base font-bold text-sm">
          Updating...
        </span>
      </div>
    );
  };

  // Sends the text from the user to create new model embeddings for prediction
  function sendUpdateModelWithText() {
    if (refUpdateEmbeddingsBtn.current.innerText === "Edit Model") {
      toggleEditMode(true);
      return;
    }
    setBtnClicked(!btnClicked);

    var textEmbeddings = refTextEmbeddingsTextBox.current.value.trim();
    let lines = textEmbeddings.split("\n");
    let use_lines = 0;

    for (let i = 0; i < lines.length; i++) {
      let l = lines[i];
      let parts = l.split(":");
      if (parts.length !== 2) {
        toast.info(
          "Invalid line: Line must contain one semicolon (:) separating the label from the description.",
          {
            theme: "dark",
          }
        );
        return;
      }
      if (parts[1].trim().split(" ").length <= 1) {
        toast.info(
          "Invalid line: Description must contain at least two words.",
          {
            theme: "dark",
          }
        );
        return;
      }
      use_lines += 1;
    }
    if (use_lines < 3) {
      toast.error("Invalid text: Must contain at least three lines.", {
        theme: "dark",
      });
      return;
    }
    refUpdateEmbeddingsBtn.current.disabled = true;
    refUpdateEmbeddingsBtn.current.style.cursor = "no-drop";
    refUpdateEmbeddingsBtn.current.style.backgroundColor = "rgb(165 180 252)";
    document.getElementById("sensitiveDiv").style.backgroundColor =
      "rgb(226 232 240)";
    refTextEmbeddingsTextBox.current.disabled = true;

    // Disable sending snapshots while we update the model
    setSendSnapshotsLoopEnabled(false);
    setLoader(true);
    refPredictedLabelBox.current.value = "Model updating...";
    refPredictionConfidenceBox.current.value = "Model updating...";

    // Send the update request
    let data = { type: "update_text", value: textEmbeddings };
    sendRequest(data);
    setUpdateTimer(APIWaitTimeoutSec);
    setErrTimerState("update");
  }

  // Model updatewhen complete will send back the text sensitivities, we want to show the user
  function recvTextSensitivitiesUpdated(data) {
    setUpdateTimer(0);
    refUpdateEmbeddingsBtn.current.disabled = false;
    refUpdateEmbeddingsBtn.current.style.cursor = "auto";
    refUpdateEmbeddingsBtn.current.style.backgroundColor = "rgb(79 70 229)";
    document.getElementById("sensitiveDiv").style.backgroundColor = "white";
    refTextEmbeddingsTextBox.current.disabled = false;
    setSendSnapshotsLoopEnabled(true);

    // receives nested array [[Label, [[DescriptionWord, Sensitivity], ...]], ...]
    if (data !== null) {
      createSensitivities(data.value);
    }

    // Make sure we toggle back to the correct mode when the update is complete
    toggleEditMode(false);
  }

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  function sendEmbeddingsRequest() {
    let data2 = { type: "request_embedding_vectors", value: "" };
    sendRequest(data2);
  }

  // Sends an image from the streaming cam to the server for prediction
  function sendImageScanRequest(dataUrl) {
    let data = { type: "process_image", value: dataUrl };
    sendRequest(data);
  }

  // Sends a notification that someone clicked to view the ios github repo
  function sendIosGithubRepoViewNotification() {
    let data = { type: "ios_repo_view", value: "" };
    sendRequest(data);
    window.open(
      "https://github.com/Passiolife/Passio-MindsEye-iOS-Distribution",
      "_blank",
      "noopener,noreferrer"
    );
  }

  // Sends an image from the streaming cam to the server for prediction
  function sendDownloadModelRequest() {
    setDlEnabled(false);
    setDlSuccess(false);
    setDlText("Downloading");
    setIsDownloading(true);
    let data = { type: "download_mlmodel", value: "" };
    setErrTimerState("download");
    setUpdateTimer(APIWaitTimeoutSec);
    sendRequest(data);
  }

  // Sends a notification that someone clicked to view the ios github repo
  function sendAndroidGithubRepoViewNotification() {
    let data = { type: "android_repo_view", value: "" };
    sendRequest(data);
    window.open(
      "https://github.com/Passiolife/Passio-MindsEye-Android-Distribution",
      "_blank",
      "noopener,noreferrer"
    );
  }

  // Sends an image from the streaming cam to the server for prediction
  function sendDownloadModelRequestAndroid() {
    setDlEnabled(false);
    setDlSuccess(false);
    setDlText("Downloading");
    setIsDownloading(true);
    let data = { type: "download_tfmodel", value: "" };
    setErrTimerState("download");
    setUpdateTimer(APIWaitTimeoutSec);
    sendRequest(data);
  }
  // Receives the users current place in the queue
  function recvDownloadModelResponse(data, ext) {
    setDlSuccess(true);
    setIsDownloading(false);
    setUpdateTimer(0);
    if (data !== null) {
      let mlmodel = data.value;
      const url = window.URL.createObjectURL(b64toBlob(mlmodel));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `My-Passio-MindsEye-Model.${ext}`);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
    setDlEnabled(true);
    setDlText("Download");
  }

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  // Rcevies the response data about an image prediction
  function recvProcessImageResponse(data) {
    let a = data.value.split(":");
    if (a.length === 2) {
      if (sendSnapshotsLoopEnabled) {
        setLoader(false);
        if (
          refPredictedLabelBox.current &&
          refPredictionConfidenceBox.current
        ) {
          refPredictedLabelBox.current.value = a[0];
          refPredictionConfidenceBox.current.value = `${Math.round(
            a[1] * 100
          )}%`;
        }
      }
    }
  }

  // Recevies the current text response from the server (UNUSED)
  function recvCurrentTextResponse(data) {
    refTextEmbeddingsTextBox.current.value = data.value;
  }

  // Recevies the current text response from the server (UNUSED)
  function recvCurrentEmbeddingVectorsResponse(data) {
    toast.error(data.value, {
      theme: "dark",
    });
  }

  // Receives the users current place in the queue
  function recvQueueStatusResponse(data) {
    setCurrentQueueNum(data.value);
  }

  // Snapshot Loop Ticker - Sends a snapshot to the server every Xms
  // as long as the sendSnapshotsLoopEnabled state flag is true
  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendSnapshotsLoopEnabled, sendQueuePosRequestsEnabled]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Defines a function to take a snap
      function captureSnapshot() {
        if (null != refStreamContainerElement.current.srcObject) {
          var ctx = refSnapshotCaptureCanvas.current.getContext("2d");
          var img = new Image();

          ctx.drawImage(
            refStreamContainerElement.current,
            0,
            0,
            refSnapshotCaptureCanvas.current.width,
            refSnapshotCaptureCanvas.current.height
          );

          let dataUrl = refSnapshotCaptureCanvas.current.toDataURL("image/png");
          img.src = dataUrl;
          img.width = 240;

          sendImageScanRequest(dataUrl);
        }
      }
      if (sendSnapshotsLoopEnabled) {
        captureSnapshot();
      }
    }, 750);

    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendSnapshotsLoopEnabled]);

  // This effect requests our queue position every second.
  // When the queue position is low enough, the server will send a connection_ready message, which is caught elsewhere
  useEffect(() => {
    const interval = setInterval(() => {
      function requestQueuePosition() {
        let data = { type: "connection_queue_status", value: "" };
        sendRequest(data);
      }
      if (sendQueuePosRequestsEnabled) {
        requestQueuePosition();
      }
    }, 1000);

    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendQueuePosRequestsEnabled]);

  // When the connection opens first, we run this to enable the main UI
  function enablePage() {
    // Disable queue requests, and change what interface we are displaying
    setSendQueuePosRequestsEnabled(false);
    refMainInterfaceDiv.current.style.display = "block";
    refLikeWhatYouDiv.current.style.display = "block";
    refInterfaceQueuedDiv.current.style.display = "none";

    // Start webcam streaming
    startStreaming();

    // Set default text embeddings
    setCurrentTextEmbeddingsText(`Person: a persons face
TV Remote: a TV remote control
Cup: A cup or mug
Hairbrush: a hairbrush
iPhone: an iphone
Blue Marker: a marker pen with a blue cap
Orange Marker: a marker pen with an orange cap
Red Marker: a marker pen with a red cap`);
  }

  // Disables the page back to a queued position
  function disablePage() {
    // Stops webcame
    stopStreaming();

    // Flips interface
    refMainInterfaceDiv.current.style.display = "block";
    refLikeWhatYouDiv.current.style.display = "block";
    refInterfaceQueuedDiv.current.style.display = "none";
  }

  function swapCamera() {
    if (facingMode === "user") {
      setFacingMode("environment");
    } else {
      setFacingMode("user");
    }
  }

  useEffect(() => {
    stopStreaming();
    startStreaming();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facingMode]);

  useEffect(() => {
    let obj = document.getElementById("btn-swap-camera");
    if (swapCamEnabled) {
      obj.style.display = "block";
    } else {
      obj.style.display = "none";
    }
  }, [swapCamEnabled]);

  // Locates users video media and begins a stream into the video element using that camera
  // Also enables the snapshot send loop
  function startStreaming() {
    if (!emailIsSubmitted()) {
      return;
    }
    const mediaSupport = "mediaDevices" in navigator;
    if (mediaSupport && null == refStreamContainerElement.current.srcObject) {
      // Begin the snapshot loop
      setSendSnapshotsLoopEnabled(true);

      // Get user media promise
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: facingMode } })
        .then(function (mediaStream) {
          // Add the media object to our stream dom object
          refStreamContainerElement.current.srcObject = mediaStream;
          refStreamContainerElement.current.play();

          // AFTER we have got user media, we can check how many cameras are available
          // if we do it before requesting access, we only get the default camera, which defeats the purpose!
          // We want to see if they have more than one camera. If its more then one, enable the swap camera option
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            // after we enumerate media devices, filter for video inputs
            let videos = devices.filter(
              (device) => device.kind === "videoinput"
            );

            // if we have more than one video input, enable the swap camera button
            if (videos.length > 1) {
              setSwapCamEnabled(true);
            } else {
              setSwapCamEnabled(false);
            }
          });
        })
        .catch(function (err) {
          // catch errors in the get user media promise
          toast.error("Unable to access camera: " + err.message, {
            theme: "dark",
          });
        });
    } else {
      if (!mediaSupport) {
        toast.error("Your browser does not support media devices.", {
          theme: "dark",
        });
      }
      return;
    }
  }

  // Kills active media streams from the user, dropping handle on the camera, and stops the snapshot send loop
  function stopStreaming() {
    setSendSnapshotsLoopEnabled(false);
    if (null != refStreamContainerElement.current.srcObject) {
      var tracks = refStreamContainerElement.current.srcObject.getTracks();
      tracks.forEach((track) => {
        track.stop();
        track.enabled = false;
      });
      refStreamContainerElement.current.srcObject = null;
    }
  }

  // Creates styling using the text sensitivity data and displays it to the user
  function styleWordFromSensitivity(word, sensitivity) {
    // when its 1 i want no effect, 0 i want full effect
    sensitivity = 1 - sensitivity;
    let weight = Number(sensitivity.toFixed(1)) * 1000;
    if (weight > 900) {
      weight = 900;
    }
    if (weight < 100) {
      weight = 100;
    }
    let style = (
      <span
        key={word + sensitivity.toString()}
        style={{
          color: `rgb(${sensitivity * 79} 70,229)`,
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: `${weight}`,
        }}
      >
        {" "}
        {word}{" "}
      </span>
    );
    return style;
  }

  // Wrapper to generate the HTML for text sensitivities
  function createSensitivities(sensMap) {
    setCurrentSensitivitiesText(
      <div key="sens">
        {sensMap.map((labelSensData) => {
          return (
            <span key={labelSensData[0]} style={{ display: "block" }}>
              <b>{labelSensData[0]}:</b>
              {labelSensData[1].map((sensPack) => {
                return styleWordFromSensitivity(sensPack[0], sensPack[1]);
              })}
            </span>
          );
        })}
      </div>
    );
  }

  return (
    <>
      <div className="px-6 mx-auto max-w-[1280px]">
        <div className="flex justify-center mt-6">
          <img src={headerLogo} alt={"header logo"} width={404} />
        </div>
        <div className="flex flex-col items-center">
          <h1 className="text-2xl sm:text-3xl md:text-5xl font-extrabold mt-8 text-gray-900">
            Welcome to{" "}
            <span className="text-passio-darkPrimary"> Minds Eye</span>
          </h1>
        </div>

        <div
          role="status"
          className="flex justify-center flex-col items-center"
          ref={refInterfaceQueuedDiv}
        >
          <svg
            aria-hidden="true"
            className="w-16 h-[100px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
          <p className="text-center text-red-400 text-base md:text-xl">
            Passio MindsEye is currently in use.
            <span className="block">You are #0 in the queue.</span>
          </p>
        </div>

        <div ref={refMainInterfaceDiv} style={{ display: "none" }}>
          <div className="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-8">
            <div>
              <div className="player-container">
                <video
                  className="bg-black w-full rounded-md"
                  id="stream"
                  ref={refStreamContainerElement}
                  playsInline={true}
                />
                <div
                  className="player-buttons"
                  id="btn-swap-camera"
                  onClick={swapCamera}
                />
              </div>
              <form id="application-form">
                <div className="grid grid-cols-2 gap-5">
                  <div className="shadow-slate-200 border rounded p-2 md:p-6">
                    <p className="text-base text-gray-500">Label</p>
                    {loader ? (
                      <Loader />
                    ) : (
                      <input
                        ref={refPredictedLabelBox}
                        type="text"
                        className="bg-white lg:text-xl md:text-base font-bold text-sm w-[100px] md:w-full"
                        id="label"
                        disabled={true}
                        defaultValue="-"
                      />
                    )}
                  </div>
                  <div className="shadow-slate-200 border rounded p-2 md:p-6">
                    <p className="text-base text-gray-500">Confidence</p>
                    {loader ? (
                      <Loader />
                    ) : (
                      <input
                        ref={refPredictionConfidenceBox}
                        type="text"
                        className="bg-white lg:text-xl md:text-base font-bold text-sm w-[100px] md:w-full"
                        id="confidence"
                        disabled={true}
                        defaultValue="-"
                      />
                    )}
                  </div>
                </div>
              </form>
              {/*HIDDEN STUFF */}
              <div className="play-area-sub" style={{ display: "none" }}>
                <canvas
                  ref={refSnapshotCaptureCanvas}
                  id="capture"
                  width="320"
                  height="240"
                />
              </div>
              {/* HIDDEN STUFF /*/}
            </div>
            <div className="border rounded-md flex flex-col justify-between">
              <div className="p-4 sm:p-6 text-sm">
                <h3 className="text-gray-700 font-medium">
                  How to Use Minds Eye
                </h3>
                <ul className="list-disc ml-8 mb-3 text-gray-500">
                  <li>
                    List at least three items to be recognized, one on each
                    line.
                  </li>
                  <li>
                    Include the item name, a colon, then a two or more word
                    description.
                  </li>
                  <li>
                    Words that affect recognition more will more colorful and
                    bold.
                  </li>
                  <li>Items higher in the list have precedence.</li>
                </ul>
                <div
                  className="rounded-md border border-gray-300"
                  id="outerDiv"
                >
                  <textarea
                    tabIndex="0"
                    ref={refTextEmbeddingsTextBox}
                    id="textAreaDiv"
                    rows="10"
                    className="p-3 leading-6 w-full min-h-[300px] overflow-hidden rounded-md"
                    defaultValue={currentTextEmbeddingsText}
                  />
                  <div
                    id="sensitiveDiv"
                    className="hidden p-3 max-h-[300px] overflow-y-auto rounded-md"
                    tabIndex="0"
                  >
                    {refUpdateEmbeddingsBtn?.current?.disabled && (
                      <div
                        role="status"
                        className="flex justify-center flex-col items-center"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-8 h-[60px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    {currentSensitivitiesText}
                  </div>
                </div>
              </div>
              <div className="flex justify-start py-3 px-6 bg-gray-100">
                <div>
                  <button
                    onClick={sendIosGithubRepoViewNotification}
                    id="btn-ios-repo"
                    type="button"
                    className={`min-w-[120px] max-w-[120px] rounded p-1 text-white text-sm mr-4 bg-indigo-600 !cursor-pointer mb-2`}
                  >
                    <span className="inline-flex align-middle justify-center mr-3">
                      <img
                        className="mr-2 ml-1"
                        src={appleLogo}
                        width={18}
                        height={15}
                      ></img>{" "}
                      Example
                    </span>
                  </button>
                  <button
                    onClick={sendDownloadModelRequest}
                    disabled={!dlEnabled}
                    id="btn-dl-mlmodel"
                    type="button"
                    className={`min-w-[120px] max-w-[120px] rounded p-1 text-white text-sm mr-3 ${
                      !dlEnabled
                        ? "bg-indigo-300"
                        : "bg-indigo-600 !cursor-pointer"
                    }`}
                  >
                    <span className="inline-flex align-middle justify-center">
                      <img
                        className="mr-2"
                        src={appleLogo}
                        width={18}
                        height={15}
                      ></img>{" "}
                      {dlText}
                    </span>
                  </button>
                </div>
                <div>
                  <button
                    onClick={sendAndroidGithubRepoViewNotification}
                    id="btn-android-repo"
                    type="button"
                    className={`button min-w-[120px] max-w-[120px] rounded p-1 text-white text-sm mr-4 bg-indigo-600 !cursor-pointer mb-2`}
                  >
                    <span className="inline-flex align-middle justify-center mr-2">
                      <img
                        className="mr-2 ml-1"
                        src={droidLogo}
                        width={15}
                        height={15}
                      ></img>{" "}
                      Example
                    </span>
                  </button>
                  <button
                    onClick={sendDownloadModelRequestAndroid}
                    disabled={!dlEnabled}
                    id="btn-dl-tfmodel"
                    type="button"
                    className={`min-w-[120px] max-w-[120px] rounded p-1 text-white text-sm mr-2 ${
                      !dlEnabled
                        ? "bg-indigo-300"
                        : "bg-indigo-600 !cursor-pointer"
                    }`}
                  >
                    <span className="inline-flex align-middle justify-center">
                      <img
                        className="mr-2 ml-1"
                        src={droidLogo}
                        width={15}
                        height={15}
                      ></img>{" "}
                      {dlText}
                    </span>
                  </button>
                </div>
                <span className="mr-auto"></span>

                <button
                  onClick={sendUpdateModelWithText}
                  ref={refUpdateEmbeddingsBtn}
                  disabled={isDownloading}
                  id="btn-update-text"
                  type="button"
                  className={`button min-w-[80px] rounded p-2 text-white !cursor-pointer ${
                    isDownloading ? "bg-indigo-300" : " bg-indigo-600"
                  }`}
                >
                  {btnText}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={refLikeWhatYouDiv}
        style={{ display: "none" }}
        className="relative isolate overflow-hidden"
      >
        <div className="mx-auto max-w-7xl px-6 pt-10 pb-6 sm:pb-8 lg:flex lg:pt-20 lg:px-8">
          <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl md:text-5xl lg:text-6xl">
              Like what you see? See our other tools!
            </h1>
            <div className="mt-5 flex sm:flex-row flex-col sm:items-center gap-x-6">
              <a
                href="https://www.passio.ai/"
                target="_blank"
                className="rounded-md bg-indigo-600 px-6 py-1 lg:py-2 text-sm md:text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-center"
                rel="noreferrer"
              >
                Passio.ai
              </a>
              <a
                href="https://platform.passiolife.com/"
                target="_blank"
                className="mt-3 sm:mt-0 rounded-md bg-indigo-600 px-6 py-1 lg:py-2 text-sm md:text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-center"
                rel="noreferrer"
              >
                Mobile AI Platform
              </a>
            </div>
          </div>
          <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mt-0 lg:mr-0 lg:max-w-none lg:flex-none">
            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
              <img
                src={browseAnnotion}
                alt="App screenshot"
                width={2432}
                height={1442}
                className="w-[76rem] rounded-md bg-white/5 ring-1 ring-white/10"
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default MindEye;
