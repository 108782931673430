import React from "react";

const Input = ({
  type,
  name,
  id,
  placeHolder,
  register,
  rules,
  label,
  errors,
  defaultValue,
  className = "",
}) => {
  return (
    <>
      <div className="flex">
        <input
          type={type}
          name={name}
          className={
            className !== ""
              ? className
              : `flex-1 block w-full rounded-md text-sm border-gray-300 xl:w-[420px] lg:w-[400px] md:w-[350px] w-[250px] p-3 rounded-md text-sm sm:text-base`
          }
          id={id}
          placeholder={placeHolder}
          defaultValue={defaultValue}
          {...register(label, { ...rules })}
        />
      </div>
      <div>
        {errors[label] && errors[label].type === "required" && (
          <p className="text-red-500 text-left text-sm">{name} is required</p>
        )}
        {errors[label] && errors[label].type === "maxLength" && (
          <p className="text-red-500 text-left text-sm">
            {name} should be have maximum of {rules.maxLength} characters
          </p>
        )}
        {errors[label] && errors[label].type === "min" && (
          <p className="text-red-500 text-left text-sm">
            {name} should be contain at least {rules.min} characters
          </p>
        )}
        {errors[label] && errors[label].type === "pattern" && (
          <p className="text-red-500 text-left text-sm">{name} is invalid</p>
        )}
      </div>
    </>
  );
};
export default Input;
