import React, { useEffect } from "react";
import SideView from "../images/sideView.jpg";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import CustomInput from "./Input";
import { useForm } from "react-hook-form";
import axios from "axios";

const Home = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: {
      email: "",
      consent: false,
    },
  });

  useEffect(() => {
    let submittedEmail = localStorage.getItem("mindseye-email-sent");
    if (submittedEmail && submittedEmail === "true") {
      navigate("/mindseye");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = (data) => {
    const request = JSON.stringify({
      email: data.email,
    });

    let host = "api-dev.passiolife.com";
    if (process.env.REACT_APP_ENV === "prod") {
      host = "api.passiolife.com";
    }

    axios
      .post(`https://${host}/playground/mindseye/hubspot/email`, request, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          reset();
          localStorage.setItem("mindseye-email-sent", "true");
          navigate("/mindseye");
        }
      });
  };

  return (
    <>
      <div className="bg-passio-gray h-full xl:h-screen">
        <Header />
        <div className="flex items-center grid h-full lg:h-[calc(100vh_-_89px)] lg:grid-cols-2 max-w-[1280px] mx-auto">
          <div className="flex items-center justify-center">
            <div className="p-10">
              <h3 className="text-3xl lg:text-4xl xl:text-5xl text-white font-extrabold">
                A Powerful Tool In
                <span className="block text-indigo-500 pt-2">
                  AI Recognition
                </span>
              </h3>
              <p className="xl:text-xl lg:text-lg sm:text-md text-base text-white mt-3 md:mt-2 xl:mt-5 max-w-[600px]">
                Passio is showcasing a new AI recognition tool that makes it
                possible to recognize anything with your device&#39;s camera.
                Please enter an email below to continue.
              </p>
              <div className="xl:mt-10 lg:mt-6 mt-4">
                <form>
                  <div className="flex xl:flex-row flex-col">
                    <div>
                      <CustomInput
                        type="email"
                        id="email"
                        label="email"
                        placeHolder="Enter your email"
                        register={register}
                        name="Email"
                        errors={errors}
                        rules={{
                          required: true,
                          pattern: /[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]+/g,
                        }}
                      />
                    </div>
                    <div>
                      <button
                        className="w-[150px] mt-3 xl:ml-3 xl:mt-0 bg-indigo-500 p-3 rounded-md text-white text-sm sm:text-base"
                        type="submit"
                        onClick={handleSubmit(submit)}
                      >
                        Try Minds Eye
                      </button>
                    </div>
                  </div>
                  <fieldset>
                    <div className="relative flex items-start mt-3">
                      <div className="flex h-5 items-center">
                        <input
                          type={"checkbox"}
                          name={"consent"}
                          className="h-4 w-4 rounded-lg border-indigo-600 text-indigo-600 focus:ring-indigo-500"
                          id="consent"
                          {...register("consent", {
                            required: "Consent is required",
                          })}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="consent"
                          className="block text-white max-w-[600px] cursor-pointer"
                        >
                          I agree to allow <b>Passio</b> to store and process my
                          personal data
                        </label>
                      </div>
                    </div>
                    {errors["consent"] &&
                      errors["consent"].type === "required" && (
                        <p className="text-red-500 text-left text-sm">
                          Consent is required
                        </p>
                      )}
                  </fieldset>
                </form>
                <span className="block mt-3 text-white max-w-[600px]">
                  We only collect your email to send you updates on our latest
                  improvements in this technology. No sign up is required!
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-center pb-10 lg:pb-10 px-10 lg:py-10 lg:px-10">
            <img src={SideView} alt={"side view"} className="rounded-lg" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
