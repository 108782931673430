import React from "react";
import headerLogo from "../images/passio-white 1.svg";
const Header = () => {
  return (
    <>
      <div className="m-auto pl-8 pt-5 max-w-[1280px]">
        <img src={headerLogo} alt={"header logo"} />
      </div>
    </>
  );
};
export default Header;
